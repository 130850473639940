@import url("https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap");

:root {
  --primary-color: #c42828;
  --white-color: #fefcfc;
}

html body {
  font-family: "Roboto Mono", monospace;
  background-color: var(--white-color);
}

a {
  color: var(--primary-color);
}

a.invertedLink {
  color: var(--white-color);
}

.App {
  margin: 0;
}

div.App > header > h1 {
  color: var(--primary-color);
}

button {
  margin: 1em 0;
  background-color: var(--white-color);
  color: var(--primary-color);
  border: 2px solid var(--primary-color);
  padding: 0.25em;
  font-family: "Roboto Mono", monospace;
}

button:hover {
  cursor: pointer;
}

input[type="file"]::file-selector-button {
  background: var(--white-color);
  color: var(--primary-color);
  padding: 0.25em;
  border: 2px solid var(--primary-color);
  font-family: "Roboto Mono", monospace;
  cursor: pointer;
}

.fileUploader {
  margin: 1em 0;
}

.fileUploader > label {
  margin: 0 1em 0 0;
  cursor: pointer;
}

pre > code {
  display: block;
  max-height: 20em;
  overflow-y: scroll;
}

.App > div:not(.landingWrapper) {
  max-width: 70em;
  margin: 2em auto;
}

@media only screen and (max-width: 74em) {
  .App > div {
    margin: 2em 2em;
  }

  .App > div:not(.landingWrapper) {
    margin: 2em 2em;
  }
}
@media only screen and (max-width: 600px) {
  .mobileHidden {
    display: none;
  }
}
